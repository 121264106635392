import * as React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const AhrenBritton = () => {
  return (
    <Layout>
      <SEO
        title="AHREN BRITTON - Management | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="management">
        <Container
          className="management-details-container"
          w="90%"
          paddingBottom={30}
          maxW={1694}
        >
          <Box className="manager-details">
            <div className="manager-details__text">
              <Link className="manager-details__back" to="/management">
                <ArrowBackIcon color="#044606" />
                BACK
              </Link>
              <StaticImage
                className="manager-details__img mobile"
                placeholder="transparent"
                src="../../images/ahren-britton.jpg"
                alt=""
              />
              <Heading
                as="h2"
                color="#044606"
                className="manager-details__name"
              >
                Ahren Britton,
                <br />
                M.A.Sc., P.Eng
              </Heading>
              <Text color="#044606" className="manager-details__position">
                Chief Technology Officer and Commercial Technology Development
                Leader
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Mr. Britton has over 17 years of experience in environmental
                engineering, with a focus on the wastewater treatment industry.
                He is a co-inventor of Ostara’s technology and developed the
                first prototype during his research at the University of British
                Columbia. His professional experience includes roles in
                engineering consulting, municipal utilities and heavy industry
                both in North America and in Australasia.
              </Text>
            </div>
            <StaticImage
              className="manager-details__img desktop"
              placeholder="transparent"
              src="../../images/ahren-britton.jpg"
              alt=""
            />
          </Box>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default AhrenBritton;
